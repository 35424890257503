// Auth
export const REGISTER = "/1ffc6c8633e78e99e1cab";
export const LOGIN_PAGE = "/login";
export const PASSWORD_RESET_PAGE = "/forget-password/reset/:uid/:token";
export const REQUEST_DEMO = "/requestDemo";

// Dashboard
export const DASHBOARD = "/dashboard";
export const REPORTS = "/reports";

export const TEMPLATE_PAGE_V1 =
  "/templates/v1/:viewType(seller-sales|seller-advertisement|seller-analytics|vendor-sales|vendor-advertisement|vendor-analytics)/:brandId/:country(India|United States|United Kingdom|United Arab Emirates|Kingdom of Saudi Arabia|Egypt|Singapore)";
export const TEMPLATE_COMPANY_PAGE_V1 =
  "/templates/v1/company/:viewType(seller-sales|seller-advertisement|seller-analytics|vendor-sales|vendor-advertisement|vendor-analytics)/:companyId/:country(India|United States|United Kingdom|United Arab Emirates|Kingdom of Saudi Arabia|Egypt|Singapore)";

export const TEMPLATE_PAGE_V2 =
  "/templates/v2/:viewType(sales|advertisement|primary-analytics|detailed-analytics)/:brandId/:country(India|United States|United Kingdom|United Arab Emirates|Kingdom of Saudi Arabia|Egypt|Singapore)/:marketplace(amazon_seller|amazon_vendor|flipkart|shopify|all|lazada|walmart|nykaa_b2b|noon_b2c|meesho_b2c|pharmeasy_b2c|bigbasket_b2b|jiomart_b2c|swiggy_b2b|blinkit_b2b|zepto_b2b)";
export const TEMPLATE_COMPANY_PAGE_V2 =
  "/templates/v2/company/:viewType(sales|advertisement|primary-analytics|detailed-analytics)/:companyId/:country(India|United States|United Kingdom|United Arab Emirates|Kingdom of Saudi Arabia|Egypt|Singapore)/:marketplace(amazon_seller|amazon_vendor|flipkart|shopify|all|lazada|walmart|nykaa_b2b|noon_b2c|meesho_b2c|pharmeasy_b2c|bigbasket_b2b|jiomart_b2c|swiggy_b2b|blinkit_b2b|zepto_b2b)";

// Accounting
export const ACCOUNTING =
  "/accounting/:type(accounting-reconciliation|procurement-invoicing|payables|receivables|adjustments|ads-wallet)/";
export const ACCOUNTING_RECONCILIATION =
  "/accounting/accounting-reconciliation/";
export const ACCOUNTING_PROCUREMENT_INVOICING =
  "/accounting/procurement-invoicing/";
export const PO_DETAILS = "/accounting/po/details/";
export const GRN_DETAILS = "/accounting/grn/details/";
export const ACCOUNTING_PAYABLES = "/accounting/payables/";
export const ACCOUNTING_RECEIVABLES = "/accounting/receivables/";
export const ACCOUNTING_ADJUSTMENTS = "/accounting/adjustments/";
export const ACCOUNTING_REFILL_ADS_WALLET = "/accounting/ads-wallet/";

// Deals & Discounts
export const DEALS_DISCOUNTS =
  "/deals-discount/:type(upcoming-deals|price-comparison|update-pricing|update-pricing-history)/";
export const DEALS_DISCOUNT = "/deals-discount/upcoming-deals/";
export const PRODUCT_PRICE_COMPARISON = "/deals-discount/price-comparison/";
export const PRODUCT_UPDATE_PRICING = "/deals-discount/update-pricing/";
export const PRODUCT_UPDATE_PRICING_HISTORY =
  "/deals-discount/update-pricing-history/";
export const PRODUCT_UPDATE_PRICING_HISTORY_DETAILS =
  "/deals-discount/update-pricing-history/details";

// Performance
export const PERFORMANCE = "/performance/:type(goals|keywords)/";
export const PERFORMANCE_GOALS = "/performance/goals/";
export const PERFORMANCE_KEYWORDS = "/performance/keywords/";

// Products
export const PRODUCTS =
  "/products/:type(new-listing|reviews|price-comparison|update-pricing|update-pricing-history)/";
export const PRODUCT_NEW_PRODUCT_LISTING = "/products/new-listing/";
export const PRODUCT_REVIEWS = "/products/reviews/";

// Inventory
export const INVENTORY =
  "/inventory/:type(inventory-health|procurement-suggestion|upload-history|inventory-forecast|request-inventory-report)/";
export const INVENTORY_HEALTH = "/inventory/inventory-health/";
export const REQUEST_REPORT = "/inventory/request-inventory-report/";
export const INVENTORY_PROCUREMENT_SUGGESTION =
  "/inventory/procurement-suggestion/";
export const INVENTORY_UPLOAD_HISTORY = "/inventory/upload-history/";
export const INVENTORY_FORECAST = "/inventory/inventory-forecast/";

// Subscription
export const SUBSCRIPTION = "/subscription/";
export const SUBSCRIPTION_PAYMENT_STATUS = "/payment";

//notifications
export const NOTIFICATION = "/notification/";

// Settings
export const SETTINGS =
  "/settings/:type(profile|password|manage-users|alerts)/";
export const SETTINGS_EDIT_PROFILE = "/settings/profile/";
export const SETTINGS_CHANGE_PASSWORD = "/settings/password/";
export const SETTINGS_MANAGE_USERS = "/settings/manage-users/";
export const SETTINGS_SETUP_ALERTS = "/settings/alerts/";

// Vendor Registration
export const VENDOR_REGISTRATION = "/vendor-registration/";
export const VENDOR_REGISTRATION_STATUS = "/vendor-registration/status";

// Helpdesk
export const HELPDESK = "/helpdesk/:type(user-guide)/";
export const HELPDESK_FAQS = "/helpdesk/faqs/";
export const HELPDESK_USER_GUIDE = "/helpdesk/user-guide/";
export const HELPDESK_PRODUCT_ROADMAP = "/helpdesk/product-roadmap/";
export const HELPDESK_GET_IN_TOUCH = "/helpdesk/get-in-touch/";
export const HELPDESK_TICKET_DETAILS =
  "/helpdesk/get-in-touch/ticket/:ticketId";

// Others
export const ACCESS_DENIED_PAGE = "/access-denied";
export const PROCUREMENT_POLICY = "/procurement-policy/";
